import * as Sentry from '@sentry/vue'
import { type UserModule } from '~/types'

export const install: UserModule = ({ app, router, isClient }) => {
  if (!isClient) {
    return
  }

  Sentry.init({
    app,
    dsn: 'https://93cd3d47d11947ad92b8ba8f562166ff@glitchtip.logger.jm1.au/15',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.1,
  })
}
